import { Card as AntCard, Divider, Flex, Typography } from "antd";
import moment from "moment";
import { memo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import useApplicationsContext from "routes/applications/list/services/applicationsContext";
import styled from "styled-components";
import { ICard } from ".";

const LaneLi = styled.li`
  margin-bottom: 8px;
`;

const Card = (props: ICard) => {
  const { i18n } = useTranslation();

  const {
    actions: { handleTaskInfo: setTaskInfo, setIsOpen },
  } = useApplicationsContext();
  return (
    <LaneLi>
      <Draggable
        draggableId={props.task._id}
        index={props.index}
        isDragDisabled={
          props.task.state.type === "new" ||
          props.task.state.type === "contract" ||
          props.task.state.type === "second_payment" ||
          props.task.state.type === "completed" ||
          props.task.state.type === "canceled"
        }
      >
        {(provided, snapshot) => (
          <AntCard
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              // userSelect: "none",
              // marginBottom: 10,
              boxShadow: snapshot.isDragging
                ? "0px 0px 15px rgba(0,0,0,0.1)"
                : "none",
              ...provided.draggableProps.style,
            }}
            onClick={() => {
              setTaskInfo(props.task);
              setIsOpen(true);
            }}
          >
            <Flex justify="space-between">
              <Typography.Text type="secondary">
                {props.task.phone_number}
              </Typography.Text>
              <Typography.Text type="secondary">
                {props.task.number}
              </Typography.Text>
            </Flex>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {props.task.first_name} {props.task.last_name}{" "}
              {props.task.family_name}
            </Typography.Title>
            <Divider style={{ margin: "10px 0" }} />
            <Flex justify="space-between">
              <Typography.Text>
                {props.task.program.name[i18n.language as "uz" | "ru" | "en"]}
              </Typography.Text>
              <Typography.Text style={{ textAlign: "right" }} type="secondary">
                {moment(props.task.updated_at).format("MM/D/YYYY, h:mm:ss")}
              </Typography.Text>
            </Flex>
          </AntCard>
        )}
      </Draggable>
    </LaneLi>
  );
};

export default memo(Card);
